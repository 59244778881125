var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"w-100 mb-4"},[_c('div',{staticClass:"p-35"},[_c('div',{staticClass:"d-flex align-items-start"},[_c('div',[_c('h4',{staticClass:"card-title mb-1"},[_vm._v("Stock Report")]),_c('b-card-text',{staticClass:"text-muted"},[_vm._v(" To use add border-bottom py-3 class in the every row styling. ")])],1)])]),_c('hr',{staticClass:"m-0"}),_c('b-card-body',{staticClass:"bg-light"},[_c('h4',{staticClass:"card-title"},[_vm._v("Select Report Options")]),_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('h6',[_vm._v("Product")]),_c('Select2',{attrs:{"options":_vm.productOptions},model:{value:(_vm.form.product),callback:function ($$v) {_vm.$set(_vm.form, "product", $$v)},expression:"form.product"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('h6',[_vm._v("From")]),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example1-datepicker","date-format-options":{
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      }},model:{value:(_vm.form.start),callback:function ($$v) {_vm.$set(_vm.form, "start", $$v)},expression:"form.start"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('h6',[_vm._v("To")]),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example2-datepicker","date-format-options":{
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      }},model:{value:(_vm.form.end),callback:function ($$v) {_vm.$set(_vm.form, "end", $$v)},expression:"form.end"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('h6',[_vm._v("Expiring Before")]),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example3-datepicker","date-format-options":{
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      }},model:{value:(_vm.form.expiry),callback:function ($$v) {_vm.$set(_vm.form, "expiry", $$v)},expression:"form.expiry"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('b-button',{staticClass:"mt-4 ml-auto",attrs:{"variant":"info"},on:{"click":_vm.resetOptions}},[_vm._v("Reset")]),_c('b-button',{staticClass:"mt-4",attrs:{"variant":"success"},on:{"click":_vm.generateData}},[_vm._v("Submit")])],1)],1)])],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-card',[_c('b-table',{staticClass:"contact-table",attrs:{"hover":"","bordered":"","responsive":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"cell(product_name)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mx-2 mb-0"},[_vm._v(_vm._s(data.item.product_code))])])]}},{key:"cell(edate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.edate,"DD-MM-YYYY"))+" ")]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }