<template>
  <b-row>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4">
            <div class="p-35">
                <div class="d-flex align-items-start">
                    <div>
                    <h4 class="card-title mb-1">Stock Report</h4>
                    <b-card-text class="text-muted">
                        To use add border-bottom py-3 class in the every row styling.
                    </b-card-text>
                    </div>
                </div>
                </div>
                <hr class="m-0" />
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <h4 class="card-title">Select Report Options</h4>

                <div class="d-flex">
                <b-col cols="12" lg="4">
                    <h6>Product</h6>
                    <Select2 
                      v-model="form.product" 
                      :options="productOptions"
                    />
                </b-col>
                <b-col cols="12" lg="2">
                    <h6>From</h6>
                    <b-form-datepicker
                        id="example1-datepicker"
                        v-model="form.start"
                        :date-format-options="{
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        }"
                        class="mb-2"
                    ></b-form-datepicker>
                </b-col>
                <b-col cols="12" lg="2">
                    <h6>To</h6>
                    <b-form-datepicker
                        id="example2-datepicker"
                        v-model="form.end"
                        :date-format-options="{
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        }"
                        class="mb-2"
                    ></b-form-datepicker>
                </b-col>
                <b-col cols="12" lg="2">
                    <h6>Expiring Before</h6>
                    <b-form-datepicker
                        id="example3-datepicker"
                        v-model="form.expiry"
                        :date-format-options="{
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        }"
                        class="mb-2"
                    ></b-form-datepicker>
                </b-col>
                <b-col cols="12" lg="2">
                    <b-button class="mt-4 ml-auto" variant="info" @click="resetOptions">Reset</b-button>
                    <b-button class="mt-4" variant="success" @click="generateData">Submit</b-button>
                </b-col>
                </div>
                </b-card-body>
        </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card>
        <b-table
        hover
        bordered
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
        >
        <!-- A custom formatted column -->
        <template #cell(product_name)="data">
            <div class="d-flex align-items-center">
            <h5 class="mx-2 mb-0">{{ data.item.product_code }}</h5>
            </div>
        </template>

        <template #cell(edate)="data">
            {{ data.item.edate | moment("DD-MM-YYYY") }}
        </template>

        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
        ></b-pagination>
    </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: "General",
  data: () => ({
    perPage:10,
    currentPage: 1,
    items: [],
    form: {
      start: null,
      end: null,
      product: null,
      //salesMan: null,
      batch: null,
      expiry: null
    },
    productOptions:[]
  }),
  created() {
  },
  mounted() {
    this.initOptions();
    this.generateData();
  },
  methods: {
    ...mapActions(["getProducts","stockMovement"]),
    initOptions(){
      this.getProducts().then(res => {
        this.productOptions = res.data.map(p => { 
          return {
            id: p.product_code,
            text: p.product_code+":"+p.product_name
          }
        })
      })
    },
    resetOptions() {
      this.form = {
        start: null,
        end: null,
        product: null,
        //salesMan: null,
        batch: null,
        expiry: null
      }
    },
    generateData() {
      this.stockMovement(this.form).then(res => {
        this.items = res.data;
      })
    }
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>
